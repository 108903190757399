$nav_toggle_angle: 135;

.navigation-toggle {
  display: none;
  position: fixed;
  top: 10px;
  transform: translateX(10px);
  width: 45px;
  height: 45px;
  cursor: pointer;
  z-index: 300;

  &::before {
    position: absolute;
    left: -25%;
    top: -25%;
    width: 2px;
    height: 150%;
    transition: background 0.5s ease;
  }

  .navigation-toggle-logo {
    position: relative;
    display: inline-block;
    width: 100%;
    color: $lt_navigation_text;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 4px;
      height: 100%;
      background-color: $lt_navigation_text;
      transform: rotate(25deg);
      transition: all 0.5s ease;
    }

    &::before {
      left: 20%;
    }

    &::after {
      right: 20%;
    }
  }
}

.navigation-toggle-input {
  position: absolute;
  clip: rect(0, 0, 0, 0);

  &:checked {
    + .navigation-toggle {
      &::before {
        content: "";
      }

      .navigation-toggle-logo {
        &::before {
          left: 21px;
          transform: rotate(#{$nav_toggle_angle}deg);
        }

        &::after {
          right: 21px;
          transform: rotate(-#{$nav_toggle_angle}deg);
        }
      }
    }
  }
}
