//
// Global Colors
//
$profile_colors: (
  "gitlab": #e24329,
  "github": #7bb9e6,
  "twitter": #1da1f2,
  "linkedin": #2767b2,
  "reddit": #ff4500,
  "instagram": #b332a7
);

$navigation_colors: (
  "home": #a5d6a7,
  "blog": #90caf9,
  "projects": #bcaaa4,
  "showcase": #ffcc80,
  "about": #b39ddb
);

$navigation_colors_hover: (
  "home": #c8e6c9,
  "blog": #bbdefb,
  "projects": #d7ccc8,
  "showcase": #ffe0b2,
  "about": #d1c4e9
);

$instagram_gradiant: (
  1: #fdf497,
  2: #fdf497,
  3: #fd5949,
  4: #d6249f,
  5: #285aeb
);

$gitlab_colors: (
  "boundary": #000,
  "left-ear": #e24329,
  "right-ear": #e24329,
  "nose": #e24329,
  "left-cheek": #fca326,
  "right-cheek": #fca326,
  "left-eye": #fc6d26,
  "right-eye": #fc6d26
);

//
// Light Theme
//
$lt_background: #f1f1f1;
$lt_navigation_text: #666666;
$lt_navigation_text_hover: #999999;
$lt_navigation_text_hover_target: #000;

$lt_navigation_link_text: #424242;
$lt_navigation_link_text_hover: #212121;

$lt_article_card_link_color: #00838f;
$lt_article_card_link_border_color: #0097a7;

$lt_article_card_timestamp_color: #616161;

$lt_code_bg: #282c34;
$lt_code_fg: #f1f1f1;

$lt_gradiant_line: linear-gradient(
  $lt_background,
  darken($lt_background, 10%),
  #999,
  #999,
  darken($lt_background, 10%),
  $lt_background
);

//
// Dark Theme
//
$dr_background: #212121;
$dr_navigation_text: #f5f5f5;
$dr_navigation_text_hover: #fafafa;
$dr_navigation_text_hover_target: #fff;

$dr_article_card_link_color: #26c6da;
$dr_article_card_link_border_color: #4dd0e1;

$dr_article_card_timestamp_color: #eeeeee;

$dr_code_bg: #37474f;
$dr_code_fg: #eceff1;

$dr_gradiant_line: linear-gradient(
  $dr_background,
  lighten($dr_background, 5%),
  lighten($dr_background, 10%),
  lighten($dr_background, 10%),
  lighten($dr_background, 5%),
  $dr_background
);
