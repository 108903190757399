@import "../vendors/mixins/bootstrap-breakpoints";
@import "../framework/mixins";

// We're not using Bootstrap Media Mixins to have a
// tighter control over container width for best
// readability experience

@media (min-width: 5120.01px) {
  $padding: 35%;

  .article-container {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@media (min-width: 3510px) and (max-width: 5120px) {
  $padding: 30%;

  .article-container {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@media (min-width: 2560px) and (max-width: 3509.98px) {
  $padding: 25%;

  .article-container {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@media (min-width: 1920px) and (max-width: 2559.98px) {
  $padding: 20%;

  .article-container {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@media (min-width: 1600px) and (max-width: 1919.98px) {
  $padding: 13%;

  .article-container {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@media (min-width: 1440px) and (max-width: 1599.98px) {
  $padding: 15%;

  .article-container {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@media (min-width: 1200px) and (max-width: 1439.98px) {
  $padding: 10%;

  .article-container {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  $padding: 15%;

  .article-container {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  $padding: 50px;

  .article-container {
    padding-left: $padding;
    padding-right: $padding;
  }
}

@media (max-width: 767.98px) {
  $padding: 30px;

  .article-container {
    padding-left: $padding;
    padding-right: $padding;

    .article-title {
      font-size: heading-size(h1) - 0.3;
    }
  }
}

@media (max-width: 576px) {
  $padding: 10px;

  .article-container {
    padding-left: $padding;
    padding-right: $padding;
  }
}
