//
// Common styles & useragent style overrides
//

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

code,
kbd,
samp,
pre {
  font-family: $font_family_monospaced;
}
