@keyframes dropShadow {
  0% {
    filter: drop-shadow(0 0 0 transparent);
  }
  100% {
    filter: drop-shadow(0 0 2px $lt_navigation_text);
  }
}

/* stylelint-disable no-descending-specificity */
body .profile-links,
body[data-theme="dark"] .profile-links {
  min-height: icon-size(xl);

  .profile-link {
    display: inline-block;

    &:hover {
      .profile-icon {
        animation: dropShadow 0.3s linear alternate forwards;
      }
    }

    &.gh .boundary,
    &.gh .octocat,
    &.tw .boundary,
    &.li .boundary,
    &.li .letter-i,
    &.li .letter-n,
    &.rd .face,
    &.rd .mouth,
    &.rd .left-eye,
    &.rd .right-eye,
    &.rd .left-ear,
    &.rd .right-ear,
    &.rd .antenna-line,
    &.rd .antenna,
    &.ig .boundary,
    &.ig .lense,
    &.ig .flash {
      fill: transparent;
      stroke: $lt_navigation_text;
      transition: all 0.2s ease-in-out;
    }

    &.gl {
      path,
      use {
        transition: all 0.2s ease-in-out;
        fill: transparent;

        &.boundary {
          fill: $lt_navigation_text;
        }
      }

      @each $name, $color in $gitlab_colors {
        &:hover {
          .#{$name} {
            fill: $color;
          }
        }
      }
    }

    &.gh {
      &:hover {
        .boundary {
          stroke: black;
          fill: darken(map-get($profile_colors, "github"), 50%);
          stroke-width: 1px;
        }

        .octocat {
          fill: map-get($profile_colors, "github");
        }
      }
    }

    &.tw {
      &:hover .boundary {
        stroke: black;
        fill: map-get($profile_colors, "twitter");
      }
    }

    &.li {
      &:hover {
        .boundary {
          stroke: black;
          fill: map-get($profile_colors, "linkedin");
        }

        .letter-i,
        .letter-n {
          stroke: transparent;
          fill: white;
        }
      }
    }

    &.rd {
      &:hover {
        .face,
        .mouth,
        .left-eye,
        .right-eye,
        .left-ear,
        .right-ear,
        .antenna-line,
        .antenna {
          stroke: map-get($profile_colors, "reddit");
        }

        .left-eye,
        .right-eye,
        .mouth {
          fill: map-get($profile_colors, "reddit");
        }

        .face,
        .left-ear,
        .right-ear,
        .antenna {
          fill: white;
        }
      }
    }

    &.ig {
      .gradiant {
        stop-color: transparent;
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        @each $index, $color in $instagram_gradiant {
          .stop-#{$index} {
            stop-color: $color;
            transition: stop-color $index * 0.1s ease-in-out;
          }
        }

        .boundary,
        .lense,
        .flash {
          stroke: white;
        }
      }
    }
  }
}
/* stylelint-enable no-descending-specificity */
