@import "../vendors/mixins/bootstrap-breakpoints";
@import "../framework/mixins";

.site-header {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  padding: 15px;
  text-align: center;
  background-color: $lt_background;
  z-index: 100;

  .site-header-link {
    display: inline-block;
    font-family: $font_family_monospaced;
    font-size: 1.3rem;
    color: $lt_navigation_text;
  }
}

.navigation-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: $lt_background;
  z-index: 200;
  overflow-x: hidden;
  overflow-y: auto;
}

.navigation-container {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  min-height: map-get($grid-breakpoints, md);
  background-color: $lt_background;

  &,
  .navigation-header,
  .navigation-footer {
    display: flex;
    align-items: center;
  }

  .navigation-header,
  .navigation-body,
  .navigation-footer {
    font-family: $font_family_monospaced;
  }

  .navigation-header,
  .navigation-footer {
    height: 100px;
  }

  .navigation-header {
    align-self: start;
    margin-top: $navigation_margin;
    margin-left: $navigation_margin;
  }

  .navigation-body {
    position: relative;
    width: 100%;
    height: 350px;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: -25%;
      width: 2px;
      height: 150%;
      background: $lt_gradiant_line;
    }
  }

  .navigation-footer {
    flex-direction: column;
    justify-content: center;
    color: $lt_navigation_text;
    margin-bottom: $navigation_margin;

    .footer-link {
      color: $lt_navigation_text;
      transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
      border-bottom: 1px solid $lt_navigation_text;

      &:hover {
        color: $lt_navigation_text_hover_target;
        border-color: $lt_navigation_text_hover_target;
        border-bottom-width: 3px;
      }
    }
  }
}

.navigation-header-link,
.navigation-links,
.profile-links {
  display: flex;
}

.navigation-links,
.profile-links {
  justify-content: space-between;
}

.navigation-header-link {
  align-items: center;
  margin: auto;

  .header-logo,
  .header-title {
    display: inline-block;
    color: $lt_navigation_text;
  }

  .header-logo {
    font-weight: bolder;
  }
}

.profile-links {
  width: 100%;
  margin-bottom: 30px;
}

.footer-text {
  text-align: center;
  font-size: 0.8rem;
}

// Large Screens (>= 1920px wide)
@include media-breakpoint-up(xxxxl) {
  $icon_size: icon-size(xxxl);

  .navigation-wrapper {
    width: navigation-width(xxxxl);
  }

  .navigation-header-link {
    .header-logo {
      font-size: 6rem;
    }

    .header-title {
      font-size: 3rem;
    }
  }

  .navigation-links {
    .navigation-link {
      font-size: 2.5rem;
    }
  }

  .navigation-footer {
    width: 60%;

    .profile-links {
      .profile-link {
        &,
        .profile-icon {
          width: $icon_size;
          height: $icon_size;
        }
      }
    }

    .mode-toggle {
      width: 120px;

      .mode .mode-icon {
        width: $icon_size;
      }
    }
  }
}

@include media-breakpoint-down(xxxxl) {
  $icon_size: icon-size(xxxl);

  .navigation-wrapper {
    width: navigation-width(xxxl);
  }

  .navigation-header-link {
    .header-logo {
      font-size: 5rem;
    }

    .header-title {
      font-size: 2.5rem;
    }
  }

  .navigation-links {
    .navigation-link {
      font-size: 2.5rem;
    }
  }

  .navigation-footer {
    width: 60%;

    .profile-links {
      .profile-link {
        &,
        .profile-icon {
          width: $icon_size;
          height: $icon_size;
        }
      }
    }

    .mode-toggle {
      width: 120px;

      .mode .mode-icon {
        width: $icon_size;
      }
    }
  }
}

// Large Screens (1440px to 1600px wide)
@include media-breakpoint-only(xxl) {
  $icon_size: icon-size(xxl);

  .navigation-header-link {
    .header-logo {
      font-size: 5rem;
    }

    .header-title {
      font-size: 2.5rem;
    }
  }

  .navigation-footer {
    width: 70%;

    .profile-links {
      .profile-link {
        &,
        .profile-icon {
          width: $icon_size;
          height: $icon_size;
        }
      }
    }

    .mode-toggle {
      width: 100px;

      .mode .mode-icon {
        width: $icon_size;
      }
    }
  }
}

// Large Screens (< 1200px wide)
@include media-breakpoint-down(xl) {
  $icon_size: icon-size(xl);

  .navigation-wrapper {
    width: navigation-width(xl);
  }

  .navigation-header-link {
    .header-logo {
      font-size: 3.5rem;
    }

    .header-title {
      font-size: 2rem;
    }
  }

  .navigation-links {
    justify-content: space-evenly;

    .navigation-link {
      font-size: 1.7rem;
    }
  }

  .navigation-footer {
    width: 75%;

    .profile-links {
      .profile-link {
        &,
        .profile-icon {
          width: $icon_size;
          height: $icon_size;
        }
      }
    }

    .mode-toggle {
      width: 100px;

      .mode .mode-icon {
        width: $icon_size;
      }
    }
  }
}

// Medium Screens (less than 1140px wide)
@include media-breakpoint-down(xl) {
  $shadow: 0 0 5px 1px lighten($lt_navigation_text, 40%);

  .site-header {
    display: block;
    transform: translateY(0);
    box-shadow: $shadow;
    transition: transform 0.3s ease-in-out;
  }

  .navigation-toggle {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }

  .navigation-wrapper {
    transition: all 0.3s ease-in-out;

    .navigation-body::after {
      background: none;
    }
  }

  .navigation-toggle-input:checked {
    + .navigation-toggle {
      transform: translateX(360px);

      &::before {
        background: none;
      }
    }

    ~ .site-header {
      transform: translateY(-100%);
    }

    ~ .navigation-wrapper {
      box-shadow: $shadow;
    }
  }
}

// Small Screens (less than 768px wide)
@include media-breakpoint-down(sm) {
  $icon_size: icon-size(sm);

  .navigation-wrapper {
    width: navigation-width(sm);

    .navigation-header {
      margin: 70px auto 0 auto;
      height: auto;

      .navigation-header-link {
        .header-logo {
          font-size: 3rem;
        }

        .header-title {
          font-size: 2rem;
        }
      }
    }

    .navigation-body {
      height: 250px;

      .navigation-links {
        text-align: center;
        margin-right: 0;
        justify-content: space-between;

        .navigation-link {
          font-size: 1.8rem;
        }
      }
    }

    .navigation-footer {
      width: 80%;
      height: 180px;
      margin-bottom: $navigation_margin;

      .profile-links {
        .profile-link {
          &,
          .profile-icon {
            width: $icon_size;
            height: $icon_size;
          }
        }
      }

      .mode-toggle {
        width: 150px;

        .mode .mode-icon {
          width: $icon_size;
        }
      }
    }
  }

  .navigation-toggle-input:checked {
    + .navigation-toggle {
      transform: translateX(10px);
    }
  }
}
