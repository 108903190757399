@function navigation-width($breakpoint) {
  @return map-get($navigation_width, $breakpoint);
}

@function icon-size($breakpoint) {
  @return map-get($icon_size, $breakpoint);
}

@function section-side-padding($breakpoint) {
  @return map-get($section_side_padding, $breakpoint);
}

@function heading-size($heading) {
  @return map-get($heading_sizes, $heading);
}

@function navigation-color($navigation-name) {
  @return map-get($navigation_colors, $navigation-name);
}

@function navigation-color-hover($navigation-name) {
  @return map-get($navigation_colors_hover, $navigation-name);
}

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
