.mode-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: icon-size(xxxl);
  margin-bottom: 30px;
  padding: 2px 4px;
  background-color: darken($lt_background, 2%);
  border-radius: 50px;
  border: 1px solid darken($lt_background, 5%);

  .mode {
    position: relative;
    line-height: 0;
    cursor: pointer;

    &::before,
    &::after {
      line-height: 1;
      font-family: $font_family_monospaced;
    }

    .mode-input {
      position: absolute;
      clip: rect(0,0,0,0);
    }

    .mode-icon {
      * {
        fill: darken($lt_background, 15%);
        transition: all .2s ease-in;
      }
    }

    &:hover {
      .mode-icon * {
        fill: darken($lt_background, 35%);
      }
    }

    .mode-input:checked + .mode-icon * {
      fill: $lt_navigation_text;
    }
  }
}
