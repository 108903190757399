@import "../vendors/mixins/bootstrap-breakpoints";
@import "../framework/mixins";

.section-container {
  position: relative;
  padding-top: 60px;

  .article-cards-list {
    column-gap: $article_card_spacing;
  }

  ::selection {
    color: $lt_navigation_text_hover_target;
    background: navigation-color-hover("blog");
  }
}

.article-card,
.article-body {
  padding: 18px;
  margin-bottom: $article_card_spacing;
  font-family: $font_family_regular;
  border-radius: 6px;

  a {
    color: $lt_article_card_link_color;
    border-bottom: 2px solid transparent;
    transition: color 0.2s ease, border 0.2s ease;

    &:hover {
      color: darken($lt_article_card_link_color, 10%);
      border-bottom: 2px solid $lt_article_card_link_border_color;
    }
  }
}

.article-card {
  display: inline-block;
  background-color: darken($lt_background, 2%);
  border: 1px solid darken($lt_background, 5%);

  .article-card-header,
  .article-card-image {
    margin-bottom: 1em;
  }

  .article-card-header,
  .article-card-footer {
    font-weight: bold;
  }

  .article-card-header {
    a {
      font-size: 1.7rem;
    }

    span {
      display: block;
      margin-top: 8px;
      font-weight: 300;
      color: $lt_article_card_timestamp_color;
      text-transform: uppercase;
    }
  }

  .article-card-image {
    display: block;
    margin: auto;
    width: 80%;
    border: 10px solid darken($lt_background, 5%);
    border-radius: 6px;
    background-color: darken($lt_background, 5%);
  }

  .article-card-body {
    color: $lt_navigation_text;
    @include font-smoothing;
  }

  .article-card-footer {
    a {
      font-size: 1.3rem;

      &::after {
        content: "\2192";
        margin-left: 5px;
        vertical-align: middle;
      }
    }
  }
}

code.highlighter-rouge {
  padding: 2px 4px;
  font-size: 1.1rem;
  color: $lt_code_fg;
  background-color: $lt_code_bg;
  border-radius: 3px;
}

div.highlighter-rouge {
  display: block;
  margin: 25px auto;
  max-width: 90%;

  > .highlight {
    overflow: auto;
    max-height: 600px;
  }

  .highlight {
    border-radius: 8px;
  }

  pre.highlight {
    padding: 10px;
    line-height: 1.5;

    > code {
      margin-right: 10px;
    }
  }
}

// Large Screens (>= 1920px wide)
@include media-breakpoint-up(xxxxl) {
  .section-container {
    margin-left: navigation-width(xxxl);
    padding-left: section-side-padding(xxxxl);
    padding-right: section-side-padding(xxxxl);

    .article-cards-list {
      column-count: 3;
    }

    .article-card {
      .article-card-header {
        a {
          font-size: 1.7rem;
          line-height: 1.7;
        }

        i {
          font-size: 1rem;
        }
      }

      .article-card-body {
        font-size: 1.3rem;
        line-height: 1.5;
      }
    }
  }
}

// Large Screens (< 1920px wide)
@include media-breakpoint-up(xxxl) {
  .section-container {
    margin-left: navigation-width(xxxl);
    padding-left: section-side-padding(xxxl);
    padding-right: section-side-padding(xxxl);

    .article-cards-list {
      column-count: 3;
    }

    .article-card {
      .article-card-header {
        a {
          font-size: 1.7rem;
          line-height: 1.7;
        }

        i {
          font-size: 1rem;
        }
      }

      .article-card-body {
        font-size: 1.3rem;
        line-height: 1.5;
      }
    }
  }
}

// Large Screens (>= 1600px wide)
@include media-breakpoint-only(xxxl) {
  .section-container {
    padding-left: section-side-padding(xxxl);
    padding-right: section-side-padding(xxxl);

    .article-cards-list {
      column-count: 2;
    }
  }
}

// Large Screens (1440px to 1600px wide)
@include media-breakpoint-only(xxl) {
  .section-container {
    margin-left: navigation-width(xxl);
    padding-left: section-side-padding(xxl);
    padding-right: section-side-padding(xxl);

    .article-cards-list {
      column-count: 2;
    }

    .article-card-body {
      font-size: 1.2rem;
      line-height: 1.1;
    }
  }
}

// Large Screens (1200px to 1440px wide)
@include media-breakpoint-only(xl) {
  .section-container {
    margin-left: navigation-width(xxxl);
    padding-left: section-side-padding(xl);
    padding-right: section-side-padding(xl);

    .article-cards-list {
      column-count: 1;

      .article-card {
        width: 100%;
      }

      .article-card-image {
        width: 50%;
      }
    }

    .article-card-body {
      font-size: 1.2rem;
    }
  }
}

// Medium Screens (less than 1200px wide)
@include media-breakpoint-down(xl) {
  .section-container {
    margin-left: 0;
    padding-top: 97px;
    padding-left: section-side-padding(lg);
    padding-right: section-side-padding(lg);

    .article-cards-list {
      column-count: 2;
      column-gap: $article_card_spacing / 2;
    }

    .article-card,
    .article-body {
      margin-bottom: $article_card_spacing / 2;
    }
  }
}

// Small Screens (less than 768px wide)
@include media-breakpoint-down(sm) {
  .section-container {
    padding-top: 87px;
    padding-left: section-side-padding(sm);
    padding-right: section-side-padding(sm);

    .article-cards-list {
      column-count: 1;
    }
  }
}
