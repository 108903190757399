@import "../vendors/mixins/bootstrap-breakpoints";
@import "../framework/mixins";

$shadow-dark: 0 0 5px 1px darken($dr_background, 5%);

@keyframes dropShadowDark {
  0% {
    filter: drop-shadow(0 0 0 transparent);
  }
  100% {
    filter: drop-shadow(0 0 2px darken($lt_background, 5%));
  }
}

body[data-theme="dark"] {
  .navigation-toggle {
    .navigation-toggle-logo {
      color: $dr_navigation_text;

      &::before,
      &::after {
        background-color: $dr_navigation_text;
      }
    }
  }

  .navigation-container {
    .navigation-header-link {
      .header-logo,
      .header-title {
        color: $dr_navigation_text;
      }
    }

    .navigation-body:after {
      background: $dr_gradiant_line;
    }

    .navigation-footer {
      color: $dr_navigation_text;

      .footer-link {
        color: $dr_navigation_text;

        &:hover {
          color: $dr_navigation_text_hover_target;
          border-color: $dr_navigation_text_hover_target;
        }
      }
    }
  }

  .section-container {
    .article-card,
    .article-body {
      a {
        color: $dr_article_card_link_color;

        &:hover {
          color: lighten($dr_article_card_link_color, 20%);
          border-bottom-color: $dr_article_card_link_border_color;
        }
      }
    }

    .article-card {
      background-color: lighten($dr_background, 2%);
      border: 1px solid lighten($dr_background, 5%);

      .article-card-header {
        span {
          color: darken($dr_navigation_text, 20%);
        }
      }

      .article-card-image {
        border-color: lighten($dr_background, 5%);
        background-color: lighten($dr_background, 5%);
      }

      .article-card-body {
        color: $dr_article_card_timestamp_color;
      }
    }

    .article-body {
      h4.article-publish-date {
        color: $dr_article_card_timestamp_color;
      }

      p,
      ul,
      table {
        color: darken($dr_navigation_text, 5%);
      }

      blockquote {
        border-left-color: lighten($dr_background, 10%);

        p {
          color: darken($dr_navigation_text, 20%);
        }
      }

      strong {
        color: $dr_navigation_text_hover_target;
      }

      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $dr_navigation_text;
      }

      h1 {
        color: $dr_navigation_text_hover_target;
      }

      table {
        border: 1px solid darken($dr_background, 10%);

        tbody {
          tr {
            &:nth-of-type(odd) {
              background-color: lighten($dr_background, 3%);
            }

            &:hover {
              background-color: lighten($dr_background, 5%);
            }
          }
        }

        th,
        td {
          border-top-color: darken($dr_background, 10%);
        }

        th {
          background-color: lighten($dr_background, 5%);
          border-bottom-color: darken($dr_background, 10%);
        }
      }
    }
  }

  .site-header {
    background-color: $dr_background;
    box-shadow: $shadow-dark;

    .site-header-link {
      color: $dr_navigation_text;
    }
  }

  .profile-links {
    .profile-link {
      &:hover {
        .profile-icon {
          animation: dropShadowDark 0.3s linear alternate forwards;
        }
      }

      &.gh .boundary,
      &.gh .octocat,
      &.tw .boundary,
      &.li .boundary,
      &.li .letter-i,
      &.li .letter-n,
      &.rd .face,
      &.rd .mouth,
      &.rd .left-eye,
      &.rd .right-eye,
      &.rd .left-ear,
      &.rd .right-ear,
      &.rd .antenna-line,
      &.rd .antenna,
      &.ig .boundary,
      &.ig .lense,
      &.ig .flash {
        stroke: $dr_navigation_text;
      }

      &.gl {
        path,
        use {
          &.boundary {
            fill: $dr_navigation_text;
          }
        }
      }
    }
  }

  .mode-toggle {
    background-color: lighten($dr_background, 10%);
    border-color: lighten($dr_background, 15%);

    .mode {
      .mode-icon {
        * {
          fill: lighten($dr_background, 25%);
        }
      }

      &:hover {
        .mode-icon * {
          fill: lighten($lt_background, 50%);
        }
      }

      .mode-input:checked + .mode-icon * {
        fill: $dr_navigation_text;
      }
    }
  }

  code.highlighter-rouge {
    color: $dr_code_fg;
    background-color: $dr_code_bg;
    font-weight: bold;
  }

  img:not(.emoji),
  video {
    border-color: lighten($dr_background, 5%);
    background-color: lighten($dr_background, 5%);
  }
}

@include media-breakpoint-down(lg) {
  body[data-theme="dark"] {
    .navigation-wrapper .navigation-body::after {
      background: none;
    }

    .navigation-toggle-input:checked {
      ~ .navigation-wrapper {
        box-shadow: $shadow-dark;
      }
    }
  }
}
