$hover_indicator_transform_from: 200%;
$hover_indicator_transform_to: 150%;

.navigation-links {
  flex-direction: column;
  height: 100%;
  margin: 0;
  margin-right: $navigation_margin;
  text-align: right;

  .navigation-link {
    position: relative;
    padding: 4px 10px;
    color: $lt_navigation_link_text;
    transition: all 0.2s ease-in-out;

    &::before,
    &::after {
      position: absolute;
      opacity: 0;
      transition: all 0.2s ease-in;
      font-weight: bolder;
    }

    @each $name, $color in $navigation_colors {
      &.navigation-#{$name} {
        background-color: navigation-color($name);

        &::before,
        &::after {
          color: $color;
        }

        &:hover,
        &.active {
          background-color: navigation-color-hover($name);
        }
      }
    }

    &::before {
      content: "{";
      left: 0;
      transform: translateX(-$hover_indicator_transform_from);
    }

    &::after {
      content: "}";
      right: 0;
      transform: translateX($hover_indicator_transform_from);
    }

    &:hover,
    &.active {
      color: $lt_navigation_link_text_hover;

      &::before,
      &::after {
        opacity: 1;
      }

      &::before {
        transform: translateX(-$hover_indicator_transform_to);
      }

      &::after {
        transform: translateX($hover_indicator_transform_to);
      }
    }
  }
}
