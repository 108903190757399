$navigation_margin: 50px;

$article_card_spacing: 50px;

$navigation_width: (
  xxxxl: 500px,
  xxxl: 450px,
  xxl: 400px,
  xl: 350px,
  sm: 100%
);

$icon_size: (
  xxxl: 34px,
  xxl: 30px,
  xl: 28px,
  sm: 30px
);

$section_side_padding: (
  xxxxl: 7%,
  xxxl: 5%,
  xxl: 100px,
  xl: 50px,
  lg: 30px,
  sm: 20px
);

$heading_sizes: (
  h1: 2.5rem,
  h2: 1.7rem,
  h3: 1.5rem,
  h4: 1.3rem,
  h5: 1.1rem,
  h6: 1rem
);
