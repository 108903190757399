//
// Site-wide Global Variables
//

// Twitter Bootstrap v4.3.1 Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1140px,
  xxl: 1440px,
  xxxl: 1600px,
  xxxxl: 1920px
) !default;
