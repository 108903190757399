/**
 * Stylesheet for Browser-specific Bug Fixes
 */

// Microsoft Edge
@supports (-ms-ime-align: auto) {
  .navigation-links,
  .profile-links {
    justify-content: space-around;
  }

  .profile-links {
    padding-left: 0;
  }
}